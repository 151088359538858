<template>
 <div style='width: 100%; height: 100%; position: relative;'>
   
     <div class='transition' :style='getControlStyle()'>
    	<!-- CONTROLS -->
    	    
		    <div style='float: top; width: 100%; vertical-align: middle;'>
		        <div style='vertical-align: middle; text-align: center; font-size: 14pt; font-weight: bold;width: 100%;'>POSITION Monitor 1.0</div>
		        
		        <br/>
			    <span class='SVcontrolLabel'>Channel/Station:</span><br/>
			   
			    <GSelectMSimple fontSize="12" :options="stations" style='width:100%' v-model="stationIds" @changed='stationChanged' :multiple="true"/>

			    <br/>
			    <div>
			    <div style='display: inline-flex; width: 45%;'>
			    <InputDatePick :auto="false"   
                            @change="dateChanged" v-model="fromDate"/>  
                            </div>
                            -
                <div style='display: inline-flex; width: 45%;'>
                <InputDatePick :auto="false"   
                            @change="dateChanged" v-model="untilDate"/>     
                 </div>           

				</div>
		        <br/>
		        <span class='SVcontrolLabel'>Filter holdings:</span><br/>
			    <GSelectMSimple fontSize="12" :options="holdings" style='width:100%' v-model=holdingIds  :multiple="true"/>
		    	<br/>
		    	<span class='SVcontrolLabel'>Filter clients:</span><br/>
			    <GSelectMSimple fontSize="12" :options="clients" style='width:100%' v-model="clientIds"  :multiple="true"/>
		    	<br/>
		    	<span class='SVcontrolLabel'>Filter products:</span><br/>
			    <GSelectMSimple fontSize="12" :options="products" style='width:100%' v-model="productIds" :multiple="true"/>
		    	<br/>
		    	<span class='SVcontrolLabel'>Filter breaktypes:</span><br/>
			    <GSelectMSimple fontSize="12" :options="bTypes" style='width:100%' v-model="bTypeIds"  :multiple="true"/>
		    	<br/>
		    	<span class='SVcontrolLabel'>Filter status:</span><br/>
			    <GSelectMSimple fontSize="12" :options="stats" style='width:100%' v-model="statIds"  :multiple="true"/>
		    	<br/>
		    	
		    	<!-- 
		    	<span class='SVcontrolLabel'>Show Controls: </span>
		    	&nbsp;
		    	<app-switch v-model="controlsRight" :checked="controlsRight"/>
		    	 -->
		    	<br/>
		    	<b style='font-size: 10pt;'>first positions</b><br/>
		    	1st <app-switch v-model="f[0]" :checked="f[0]"/>
		    	2nd <app-switch v-model="f[1]" :checked="f[1]"/>
		    	3rd <app-switch v-model="f[2]" :checked="f[2]"/>
		    	<br/><br/>
		    	<b style='font-size: 10pt;'>last positions</b><br/>
		    	L-3 <app-switch v-model="l[2]" :checked="l[2]"/>
		    	L-2 <app-switch v-model="l[1]" :checked="l[1]"/>
		    	Lst <app-switch v-model="l[0]" :checked="l[0]"/>
		    	
		    	<div style='margin-top: 8pt;' class="BMSection">
		              <span class='BMSectionDetail dontWrap90 smallText'><b>ACTIONS</b></span>
		        </div>
			    <button :disabled='false' class='button myButton' @click="analyse">Analyse</button>
			    <button :disabled='false' class='button myButton' @click="clearFilter">Clear filter</button>
		    	
		    </div> <!-- CONTROLS -->
    </div>
    
    <!-- MAIN -->
	<div class='transition' style='float: left; width: 74%;'> 
	    <!-- HEADER -->
	    <div style='float: top; width: 100%; height: 28pt; overflow-y: scroll;'>
		
	  	</div>
	    <!-- HEADER -->
	    
	    <!-- TABLE -->
	    <div style='width: 100%; height: calc(100vh - 150px); overflow-y: scroll;'>
	        <b>Total spots: {{data.length}}</b><br/>
	        <div class='transition' :style="(data && data.length)?'':'color: #fff;'">
	        Filtered spots: {{filter(data).length}}<br/>
	        Booked Breaks: {{nBreaks}}<br/>
	        Possible good positions: {{countPos()*nBreaks}}<br/>
	        Good positions (total): {{good(data).length}}<br/>
	        Good positions (filter): {{good(filter(data)).length}}<br/>
	        
	    	Percentage (filter): {{Math.round(10000*good(filter(data)).length/filter(data).length)/100}}%<br/>
	    	Percentage (total): {{Math.round(10000*good(data).length/data.length)/100}}%<br/>
	    	
	    	</div>
	    	
	    	<br/>
	    	<div style='width: 75%; height: 16pt; overflow-y: scroll; position: relative; font-size: 12px;'>
	    	<div class="htd1">CLIENT</div><div class="htd0">HOLDING</div><div class="htd2">Good Positions</div><div class="htd2">Spots</div><div class="htd2">% </div>
	    	</div>
	    	<div class="transition" :style="'height:'+((showGraph)?'calc(15vH);':'calc(56vH);')+'width: 75%; overflow-y: scroll; position: relative; font-size: 12px;'">
		    	<div v-if="!data || !data.length">
		    			<center>
		    			<div style='margin-top: 40pt; font-size: 14pt; color: #aaa;'>
		    			select period and channels and press 'analyse'
		    			</div>
		    			</center>
		    	</div>
		    	<div v-else v-for="(p,idx) in byClient" :key="'c'+idx" @click="select(p,1)">
		    		<div v-if="p" class='item'>
		    		<div class="td1">{{p.name}}</div>
		    		<div class="td0">{{p.holding}}</div>
		    		<div class="td2">{{p.good}}</div>
		    		<div class="td2"> {{p.spots}} </div>
		    		<div class="td2"> {{Math.round(10000*p.perc)/100}}% </div>
		    		</div>
		    	</div>
	    	</div>
	    	
	    	<div class="transition" :style="'height:'+((showGraph)?'calc(40vH);':'0pt;')+'border-top: solid 1pt black; width: 75%; overflow-y: scroll; position: relative; font-size: 12px;'">
	    	    
	    	     <vuetiful-board 
			    :theme="green"
			    :dark-mode="false"
			    :col-num="1"
			    :row-height="18"
			    :layout-editable="false"
			    :datasets="[
			      {
			          chartInfo: {
			            series: positions,
			            options: {
			              chart: {
			                type: 'bar',
			              },
			              title: {
			                text: graphText(),
			                align: 'center',
			                style: {
			                  fontSize: '16px',
			                  fontWeight: 'normal',
			                },
			              },
			             labels: labels,
			              fill: {
			                opacity: .66,
			              },
			              legend: {
			                position: 'bottom',
			              }
			            }
			          },
			          gridInfo: {
			            x: 0, y: 0, w: 6, h: 12, i: '1', static: true
			          },
			        },
			    ]"
			  />	
	    	</div>
    	</div>  <!-- TABLE -->
    </div>  <!-- MAIN -->
    
 </div>
</template>

<script>
import {HTTP, fwAPI, invAPI, userAPI, reportAPI, setReload, myLocale} from '@/variables.js';
import InputTime from '@/components/inputElements/InputTime2';
import Switch from '@/components/Switch';
import GSelectMSimple from '@/components/GSelectMSimple';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import InputTimezone from '@/components/inputElements/InputTimezone';
import GridBreakView from '@/components/GridBreakView';
import InputDatePick from '@/components/inputElements/InputDatePick3'; 
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

var timers = [];

export default {
  name: 'GFWCornerPosView',
  components : {
   'app-switch': Switch, InputDatePick, GSelectMSimple
  },
  data () {
    return {
    
      stationIds: [],
      l: [true, false, false],
      f: [true, false, false],
      currency: {},
      stations: [],
      clients: [],
      clientIds: [],
      holdings: [],
      holdingIds: [],
      products: [],
      productIds: [],
      bTypes: [],
      bTypeIds: [],
      stats: [],
      statIds: [],
      data: [],
      breaks: [],
      byClient: [],
      
      selectedClient: null,
      positions: [{name: "", data: []}],
      labels: [1],
      showGraph: 0,
     
      nBreaks: 0,
      fromDate: {},
      untilDate: {},
      loadingActive: false,
      controlsRight: false
    }
  },
  methods: {
    myLocale() { return myLocale();},
    graphText()
    {
    	return "spot positions" + (this.selectedClient?" for " + this.selectedClient.name:"");
    },
    select(p, display)
    {
    	this.selectedClient = p;
    	let check = this.filter(this.filterSelected( this.data));
    	let pos = [];
    	for ( let i in check )
    	{
    		let s = check[i];
    		let posi = s.position;
    		let ePos = 0;
    		let max = this.breaks[this.breakKey(s)];
    		for ( let y=0; y < 3; y++)
        	{
    			if (this.f[y] && posi==(y+1))
    	    	{
    	    		ePos = y+1;
    	    		break;
    	    	}
        	}
    		if ( !ePos )
    		{
	        	for ( let y=0; y < 3; y++)
	        	{
	        		if (this.l[y] && posi==(max-y))
	    	    	{
	    	    		ePos = (7-y);
	    	    		break;
	    	    	}
	        	}
    		}
    		if (!ePos)
    		{
    			ePos = 4;	
    		}
    		
    		let p = pos[ePos-1]
    		if ( !p)
    		{
    			p = 0;
    		}
    		p++;
    		pos[ePos-1]=p;
    	}
    	this.labels = [];
    	let printPos=[];
    	for ( let ii in pos )
    	{
    		if ( ii == 0 )
    		{
    			this.labels.push( "First position");
    		}
    		else if ( ii == 1 )
    		{
    			this.labels.push( "2nd");
    		}
    		else if ( ii == 2 )
    		{
    			this.labels.push( "3rd");
    		}
    		else if ( ii == 6 )
    		{
    			this.labels.push( "Last position");
    		}
    		else if ( ii == 3 )
    		{
    			this.labels.push( "Middle (not relevant)");
    		}
    		else if ( ii == 4 )
    		{
    			this.labels.push( "3rd last");
    		}
    		else if ( ii == 5 )
    		{
    			this.labels.push( "2nd last");
    		}
    		else
    		{
    			this.labels.push( parseInt(ii)+1)
    		}
    		let x = pos[ii];
    		printPos.push(x);
    	}
    	this.positions = [{name: "Spots", data: printPos}]
    	this.showGraph = display;
    	
    },
    getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 25%; height: calc(100vh - 120px); padding: 2ex;"
    	}
    	return "float: left; width: 25%; height: calc(100vh - 120px); padding: 2ex;"
    },
    countPos()
    {
    	let p = 0;
    	for ( let i=0; i < 3; i++)
    	{
	    	if (this.f[i] )
	    	{
	    		p++;
	    	}
    	}
    	for ( let i=0; i < 3; i++)
    	{
	    	if (this.l[i])
	    	{
	    		p++;
	    	}
    	}
    	return p;
    },
    filterSelected(data)
    {
    	let p = data.filter(p => p.clientId==this.selectedClient.id);
    	return p;
    },
    filter(data)
    {
    	let p = data.filter(p => (this.clientIds.length == 0 || this.clientIds.includes(p.clientId)));
    	
    	p = p.filter(p=> this.holdingIds.length == 0 || this.holdingIds.includes(p.clientGroupId));
    	p = p.filter(p=> this.statIds.length == 0 || this.statIds.includes(p.statusId));
    	p = p.filter(p=> this.productIds.length == 0 || this.productIds.includes(p.productId));
    	p = p.filter(p=> this.bTypeIds.length == 0 || this.bTypeIds.includes(p.breakTypeId));
    	return p;
    },
    goodSpot( p)
    {
    	for ( let i=0; i < 3; i++)
    	{
	    	if (this.f[i] && p.position==(i+1))
	    	{
	    		return true;
	    	}
    	}
    	for ( let i=0; i < 3; i++)
    	{
	    	if (this.l[i] && p.position==(this.breaks[this.breakKey(p)]-i))
	    	{
	    		return true;
	    	}
    	}
    },
    good(data)
    {
    	return data.filter(p=> this.goodSpot(p));
    },
    getStations() {
        let that = this;
		this.startLoader()

        let time = new Date().getTime();
		let userLoad = new Promise(function (resolve, reject) {
            console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/asOptions/true/0");
       		HTTP.get( userAPI+"/getById/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.login+"/"+sessionStorage.userId)
        		 .then( response => 
                 {
                	 that.user = response.data;
                	 that.stationIds = that.user.media;
                	 
                	 resolve (that.stationIds);
                 }).catch(e => {
                     that.$toast.error("loading user: " + e.response.data, 'Error', { position: "topRight" });
                     that.stopLoader();
                     reject ();
                 });
	      });
		
		userLoad.then( stationIds => {
			
	    	let stationLoad = new Promise(function (resolve, reject) {
	            //console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/asOptions/true/0");
	            HTTP.get( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/BreakType/allEntities/true/0")
	        		 .then( response => 
	                 {
	                	 that.bTypes = [];
	                	 that.bTypeIds=[];
	                 	for ( let x in response.data.data )
	                 	{
	                 		let dx = response.data.data[x]
		                 	if ( !that.bTypes.find(p=>p.id==dx.breakTypeId))
	                 		{
	                 			that.bTypes.push({ "id": dx.id, "label": dx.shortname+"/"+dx.name, "visible":true })
	                 		}
	                 		if ( dx.positionRelevant )
	                 		{
	                 			that.bTypeIds.push( dx.id);
	                 		}
	                 	}
	                                     
	                 }).catch(e => {
	                        this.$toast.error("loading data for media/stations: " + e.response.data, 'Error', { position: "topRight" });
	                       
	                    });
	            HTTP.get( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/allEntities/true/0")
	        		 .then( response => 
	                 {
	                 	let dur = (new Date().getTime()-time);
	               		console.log("LOADED STATIONS in " + dur +"ms");  
	               		let allStations = response.data.data;
	               		
	                    allStations.sort((a, b) => {
    					    let fa = a.name.toLowerCase(),
    					        fb = b.name.toLowerCase();
    					
    					    if (fa < fb) {
    					        return -1;
    					    }
    					    if (fa > fb) {
    					        return 1;
    					    }
    					    return 0;
    					});
	                    that.stations = [];
	                   
	                    for ( var channel in allStations )
	                    {
	                    	let station = allStations[channel];
	                    	if ( station )
	                    	{
	                    		that.stations.push( { "id": station.id, "label": station.name, "visible":true });
	                    	}
	                    	
	                    }   
	                    
	                    resolve ();                    
	                 }).catch(e => {
	                        this.$toast.error("loading data for media/stations: " + e.response.data, 'Error', { position: "topRight" });
	                        reject ();
	                    });
		      });
		});	
    },
    breakKey(spot)
    {
    	return spot.mediaId+"."+spot.gridId+"."+spot.date+"."+spot.breakNo+"."+spot.breakTypeId;
    },
    clearFilter()
    {
    	let that = this;
    	that.clientIds=[];
     	that.productIds=[];
     	that.statIds=[];
     	that.holdingIds=[];
     	that.bTypeIds=[];
     	that.selectedClient = null;
     	that.showGraph = 0;
     	that.calculate();
    },
    analyse() {
        let that = this;
		this.startLoader();
		
        
		let req = {	channelIds: this.stationIds, 
					fromDateISO: new Date(this.fromDate).toISOString().split('T')[0], 
					untilDateISO: new Date(this.untilDate).toISOString().split('T')[0] };
		//alert( JSON.stringify(req))
        let time = new Date().getTime();
        
    	let dataLoad = new Promise(function (resolve, reject) {
            console.log( reportAPI+"/getSpots/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId);
            
       		HTTP.post( reportAPI+"/getSpots/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, req)

        		 .then( response => 
                 {
                 	that.data = response.data; 
                 	
                 	that.selectedClient = null;
                 	that.showGraph = 0;
                 	that.clientIds=[];
                 	that.productIds=[];
                 	that.statIds=[];
                 	
                 	that.clients = [];
                 	that.holdings = [];
                 	that.stats = [];
                 	
                 	that.products = [];
                 	for ( let d in that.data )
                 	{
                 		let dx = that.data[d];
                 		let holdingName = dx.clientGroupName
                 		if ( dx.clientGroupId === null)
                 		{
                 			dx.clientGroupId = 0;
                 			holdingName = "(without)";
                 		}
                 		if ( !that.holdings.find(p=>p.id==dx.clientGroupId))
                 		{
                 			that.holdings.push({ "id": dx.clientGroupId, "label": holdingName, "visible":true })
                 		}
                 		if ( !that.clients.find(p=>p.id==dx.clientId))
                 		{
                 			that.clients.push({ "id": dx.clientId, "label": dx.clientName, "visible":true })
                 		}
                 		if ( !that.products.find(p=>p.id==dx.productId))
                 		{
                 			that.products.push({ "id": dx.productId, "label": dx.productName, "visible":true })
                 		}
                 		if ( !that.stats.find(p=>p.id==dx.statusId))
                 		{
                 			that.stats.push({ "id": dx.statusId, "label": dx.statusName, "visible":true })
                 		}
                 		

           
                 	}
                 	that.calculate();
					
                 }).catch(e => {
                        this.$toast.error("loading data for media/stations: " + e.response.data, 'Error', { position: "topRight" });
                        reject ();
                    });
	      });
    },
    calculate() {
        let that = this;
        that.nBreaks = 0;
     	that.breaks = [];
     	
     	that.byClient = [];
     	for ( let d in that.data)
     	{
     		let dx = that.data[d];
     		let breakKey = that.breakKey( dx);
     		if ( !that.breaks[breakKey])
     		{
     			that.breaks[breakKey] = 0;
     			that.nBreaks++;
     		}
     		that.breaks[breakKey]++;
     		
     	}
     	let fData = this.filter(that.data);
     	for ( let d in fData )
     	{
     		let dx = fData[d];
     		
     		if ( !that.byClient[dx.clientId])
     		{
     			that.byClient[dx.clientId]={spots:0, good: 0, name: dx.clientName, holding: dx.clientGroupName, id: dx.clientId, perc: 0.0};
     		}
     		that.byClient[dx.clientId].spots++;
     		that.byClient[dx.clientId].good += that.goodSpot(dx)?1:0;
     	}
     	for ( let d in that.byClient )
     	{
     		let bc = that.byClient[d];
     		bc.perc = bc.good/bc.spots;
     	}
     	if ( that.selectedClient )
     	{
     		let check = this.filter(this.filterSelected( this.data));
     		if ( check.length )
     		{	
     			that.select(that.selectedClient,1)
     		}
     		else
     		{
     			that.selectedClient = null;
     			that.showGraph = 0;
     		}
     	}
     	that.byClient.sort((a, b) => b.perc-a.perc);
    },
    startLoader()
    {
    	if ( !this.loadingActive)
      	{
	      	this.loadingActive = true;
	      	this.loader = this.$loading.show({
	                    // Optional parameters
	                    container: this.$refs.formContainer,
	                    canCancel: true,
	                    programmatic: false,
	                    onCancel: this.onCancel,
	                    color: '#000000',
					    loader: 'dots',
					    width: 64,
					    height: 64,
					    active: true,
					    backgroundColor: '#ffffff',
					    opacity: 0.5,
					    zIndex: 999,
	                });
	    }
    },
    stopLoader()
	{
		clearInterval( timers.pop());
		this.loadingActive = false;
		this.loader.hide();
	}
  },
  created() {

  	let date = new Date();
  	this.fromDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  	this.untilDate = new Date(date.getFullYear(), date.getMonth(), 0);
  	this.getStations();
  },
  watch: {
  	fromDate: function() { this.getData() },
  	untilDate: function() { this.getData() },
  	clientIds: function() { this.calculate() },
  	holdingIds: function() { this.calculate() },
  	productIds: function() { this.calculate() },
  	statIds: function() { this.calculate() },
  	bTypeIds: function() { this.calculate() },
  	l: function() { this.calculate() },
  	f: function() { this.calculate() },
  },
  updated() {
  		//console.log("update...");
	  this.$nextTick(function () {
		    // Code that will run only after the
		    // entire view has been re-rendered
		    //console.log("stopping loader");
		    if (this.loadingActive)
		    {
		        timers.push( setTimeout( this.stopLoader, 100));
		    }
		  })
 	}
}
</script>
<style scoped>

 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
  
.transitionFast {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
 .BMSection {
    margin-top: 3pt;
	height:20pt; 
	width: 100%;
	border: 1pt ridge #ccc;
	vertical-align: middle; 
	text-align: center;
	display: inline-flex; 
	color: #666; 
	background-color: #eee; 
	font-weight: bold; 
	font-size: 10pt;
}
.BMSectionDetail {
    padding-top:  3pt; 
    padding-left: 20pt;
    text-align: center;
	width: 100%;
}
.header { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt; 
  font-weight: bold; 
  width: 100% !important; 
  height: 28pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  background-color: #eef; 
  }
.headerDay { 
  font-size: 12pt; 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; 
  height: 28pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc; 
  background-color: #eef; 
 }
.adMain { 
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; 
  z-Index: 17; 
  background-color: #fff; 
  margin-left: 4pt; 
  margin-right: 4pt; 
  color: #666; 
  width: 95%; 

    opacity:1;
    transition: all .5s;
   -webkit-transition: all .5s;
}
.td0 {
  display: inline-flex;
  font-size: 10pt;
  width: 24%; 
}
.td1 {
  display: inline-flex;
  font-size: 10pt;
  width: 40%; 
}
.td2 {
  display: inline-flex;
  font-size: 10pt;
  width: 12%; 
}
.htd0 {
  display: inline-flex;
  background-color: #aac;
  font-weight: bold;
  color: #fff;
  font-size: 10pt;
  width: 24%; 
}
.htd1 {
  display: inline-flex;
  background-color: #aac;
  font-weight: bold;
  color: #fff;
  font-size: 10pt;
  width: 40%; 
}
.htd2 {
  display: inline-flex;
  background-color: #aac;
  font-weight: bold;
  color: #fff;
  font-size: 10pt;
  width: 12%; 
}
.gridMain { position: absolute; z-Index: 2; text-align: center; width: 99% ;border: 1pt solid #aaa; opacity:1;
    transition: all 1s;
   -webkit-transition: all 1s; }
   
.SVcontrolLabel {
	font-weight: bold;
	font-size: 9pt;
	padding-top: 7pt;
}
.item { cursor: pointer; display: block; white-space: nowrap; }
.item:hover { background-color: #888 !important; color: #fff; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7); }
</style>
